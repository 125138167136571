var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"flat":_vm.$vuetify.theme.isDark}},[_c('v-card-title',{staticClass:"mb-2"},[_c('v-btn',{attrs:{"text":"","disabled":!_vm.adminRole && !_vm.reservationAddRole,"outlined":"","depressed":"","color":"secondary"},on:{"click":function($event){return _vm.openAddDialog()}}},[_c('v-icon',{staticClass:"ml-1"},[_vm._v("mdi-plus-circle")]),_vm._v(" حجز جديد ")],1),_c('v-divider',{staticClass:"mx-4 my-1",attrs:{"vertical":""}}),_c('v-spacer'),_c('search-in-data-table')],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"item-class":_vm.itemRowBackground,"loader-height":"10","search":_vm.search,"loading":_vm.loading,"no-data-text":"لا توجد بيانات","loading-text":"جاري تحميل البيانات","hide-default-footer":"","page":_vm.page,"items-per-page":_vm.itemsPerPage},on:{"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"item.checkinDate",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"primary--text"},[_vm._v(" "+_vm._s(_vm._f("formatDate")(item.checkinDate))+" ")])]}},{key:"item.checkoutTime",fn:function(ref){
var item = ref.item;
return [(item.checkoutTime == null)?_c('span',{staticClass:"error--text"},[_vm._v(" غير محدد ")]):_vm._e(),_c('span',{staticClass:"primary--text"},[_vm._v(" "+_vm._s(_vm._f("formatDate")(item.checkoutDate))+" ")])]}},{key:"item.checkoutDate",fn:function(ref){
var item = ref.item;
return [(item.checkoutDate == null)?_c('span',{staticClass:"error--text"},[_vm._v(" غير محدد ")]):_vm._e(),_c('span',{staticClass:"primary--text"},[_vm._v(" "+_vm._s(_vm._f("formatDate")(item.checkoutDate))+" ")])]}},{key:"item.reservationType",fn:function(ref){
var item = ref.item;
return [(item.reservationType == 0)?_c('span',[_vm._v(" عادي ")]):_vm._e(),(item.reservationType == 1)?_c('span',[_vm._v(" وفد ")]):_vm._e()]}},{key:"item.modifiedUserid",fn:function(ref){
var item = ref.item;
return [(item.modifiedUserid == null)?_c('span',[_vm._v(" ليس بعد ")]):_vm._e()]}},{key:"item.delegationCount",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","color":"primary"}},[_vm._v(" "+_vm._s(item.delegationCount)+" "),_c('small',{staticClass:"mr-1"},[_vm._v("شخص")])])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":item.status ? 'primary' : '',"x-small":"","fab":"","depressed":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.sendMessage(item)}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"ml-1",attrs:{"color":"cyan"}},[_vm._v(" mdi-telegram ")]),_vm._v(" ارسال تفاصيل الحجز ")],1)],1),_c('v-list-item',{on:{"click":function($event){return _vm.details(item)}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"ml-1",attrs:{"color":"primary"}},[_vm._v(" mdi-eye ")]),_vm._v(" تفاصيل الحجز ")],1)],1),_c('v-list-item',{attrs:{"disabled":item.status},on:{"click":function($event){return _vm.offReservation(item.id)}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"ml-1",attrs:{"color":"primary"}},[_vm._v(" mdi-minus-circle-off-outline ")]),_vm._v(" إنهاء الحجز ")],1)],1),_c('v-list-item',{on:{"click":function($event){return _vm.openEditDialog(item)}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"ml-2",attrs:{"color":"secondary"}},[_vm._v(" mdi-pencil-box-multiple ")]),_vm._v(" تعديل الحجز ")],1)],1),_c('v-list-item',{attrs:{"disabled":!_vm.adminRole && !_vm.reservationDeleteRole},on:{"click":function($event){return _vm.deleteItem(item.id)}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"ml-1",attrs:{"color":"red"}},[_vm._v(" mdi-delete-alert-outline ")]),_vm._v(" حذف الحجز ")],1)],1)],1)],1)]}}],null,true)}),_c('v-divider'),_c('v-pagination',{staticClass:"py-6 mx-auto",attrs:{"length":_vm.pageCount,"total-visible":7},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1),_c('Dialog',{ref:"dialog"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }