<template>
    <div>
        <v-card :flat="$vuetify.theme.isDark">
            <v-card-title class="mb-2">
                <v-btn
                    text
                    :disabled="!adminRole && !reservationAddRole"
                    outlined
                    depressed
                    color="secondary"
                    @click="openAddDialog()"
                >
                    <v-icon class="ml-1">mdi-plus-circle</v-icon>
                    حجز جديد
                </v-btn>
                <v-divider class="mx-4 my-1" vertical> </v-divider>
                <v-spacer></v-spacer>

                <search-in-data-table />
            </v-card-title>

            <v-data-table
                :headers="headers"
                :items="items"
                :item-class="itemRowBackground"
                loader-height="10"
                :search="search"
                :loading="loading"
                no-data-text="لا توجد بيانات"
                loading-text="جاري تحميل البيانات"
                class="elevation-1"
                hide-default-footer
                :page.sync="page"
                :items-per-page="itemsPerPage"
                @page-count="pageCount = $event">

                <template v-slot:[`item.checkinDate`]="{ item }">
                    <span class="primary--text">
                        {{ item.checkinDate | formatDate }}
                    </span>
                </template>

                <template v-slot:[`item.checkoutTime`]="{ item }">
                    <span v-if="item.checkoutTime == null" class="error--text">
                        غير محدد
                    </span>
                    <span class="primary--text">
                        {{ item.checkoutDate | formatDate }}
                    </span>
                </template>

                <template v-slot:[`item.checkoutDate`]="{ item }">
                    <span v-if="item.checkoutDate == null" class="error--text">
                        غير محدد
                    </span>
                    <span class="primary--text">
                        {{ item.checkoutDate | formatDate }}
                    </span>
                </template>

                <template v-slot:[`item.reservationType`]="{ item }">
                    <span v-if="item.reservationType == 0">
                        عادي
                    </span>
                    <span v-if="item.reservationType == 1">
                        وفد
                    </span>
                </template>

                <template v-slot:[`item.modifiedUserid`]="{ item }">
                    <span v-if="item.modifiedUserid == null">
                        ليس بعد
                    </span>
                </template>

                <template v-slot:[`item.delegationCount`]="{ item }">
                    <v-chip small color="primary">
                        {{ item.delegationCount }}
                        <small class="mr-1">شخص</small>
                    </v-chip>
                </template>

                <template v-slot:[`item.actions`]="{ item }">
                    <v-menu bottom right>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                :color="item.status ? 'primary' : ''"
                                x-small
                                fab
                                depressed
                                v-bind="attrs"
                                v-on="on">
                                <v-icon>mdi-dots-vertical</v-icon>
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item @click="sendMessage(item)">
                                <v-list-item-title>
                                    <v-icon color="cyan" class="ml-1">
                                        mdi-telegram
                                    </v-icon>
                                    ارسال تفاصيل الحجز
                                </v-list-item-title>
                            </v-list-item>

                            <v-list-item @click="details(item)">
                                <v-list-item-title>
                                    <v-icon color="primary" class="ml-1">
                                        mdi-eye
                                    </v-icon>
                                    تفاصيل الحجز
                                </v-list-item-title>
                            </v-list-item>

                            <v-list-item
                                @click="offReservation(item.id)"
                                :disabled="item.status"
                            >
                                <v-list-item-title>
                                    <v-icon color="primary" class="ml-1">
                                        mdi-minus-circle-off-outline
                                    </v-icon>
                                    إنهاء الحجز
                                </v-list-item-title>
                            </v-list-item>

                            <v-list-item @click="openEditDialog(item)">
                                <v-list-item-title>
                                    <v-icon class="ml-2" color="secondary">
                                        mdi-pencil-box-multiple
                                    </v-icon>
                                    تعديل الحجز
                                </v-list-item-title>
                            </v-list-item>

                            <v-list-item
                                :disabled="!adminRole && !reservationDeleteRole"
                                @click="deleteItem(item.id)">
                                <v-list-item-title>
                                    <v-icon color="red" class="ml-1">
                                        mdi-delete-alert-outline
                                    </v-icon>
                                    حذف الحجز
                                </v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </template>
            </v-data-table>
            <v-divider></v-divider>
            <v-pagination
                v-model="page"
                :length="pageCount"
                :total-visible="7"
                class="py-6 mx-auto">
            </v-pagination>
        </v-card>
        <Dialog ref="dialog" />
    </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import SearchInDataTable from "../../components/SearchInDataTable.vue";

import Dialog from "./Dialog.vue";

export default {
    data() {
        return {
            loading: false,
            isfullscreen: false,
            page: 1,
            pageCount: 0,
            itemsPerPage: 30,
            headers: [
                {
                    text: "رقم الحجز  ",
                    value: "reservationNo"
                },
                {
                    text: "نوع الحجز  ",
                    value: "reservationType"
                },
                {
                    text: "وقت الحجز ",
                    value: "checkinTime"
                },
                {
                    text: "تاريخ الحجز ",
                    value: "checkinDate"
                },
                {
                    text: "وقت المغادرة ",
                    value: "checkoutTime",
                    sortable: false
                },
                {
                    text: "تاريخ المغادرة ",
                    value: "checkoutDate",
                    sortable: false
                },
                {
                    text: "أسم الوفد",
                    value: "delegationName",
                    sortable: false
                },
                {
                    text: "معرف الوفد",
                    value: "delegationID",
                    sortable: false
                },
                {
                    text: "عدد الغرف المحجوزة ",
                    value: "reservedRoomsCount",
                    sortable: false
                },
                {
                    text: "عدد الاعضاء ",
                    value: "delegationCount",
                    sortable: false
                },
                {
                    text: "المعرف",
                    value: "identifierIdentifierName",
                    sortable: false
                },
                {
                    text: " المستخدم الذي قام باضافة الحجز",
                    value: "createdUserUserName"
                },
                {
                    text: "الإجراءات",
                    value: "actions",
                    align: "left",
                    sortable: false
                }
            ],
            items: [],
            detail: ""
        };
    },
    components: {
        Dialog,
        SearchInDataTable
    },

    computed: {
        ...mapState(["search"]),
        ...mapGetters("roles", [
            "adminRole",
            "reservationAddRole",
            "reservationEditRole",
            "reservationDeleteRole"
        ])
    },

    created() {
        this.$eventBus.$on(this.$route.path, () => {
            this.getItems();
        });
        this.getItems();
        this.$eventBus.$on("openDirectReservation", () => {
            this.openAddDialog();
        });
    },

    methods: {
        getItems() {
            this.loading = true;
            this.$GenericService
                .getAll("reservation/getReservations")
                .then(res => {
                    this.items = res.data.data;
                    this.loading = false;
                })
                .catch(err => {});
        },

        deleteItem(id) {
            this.$GenericService.swalAlertConfirm({}).then(result => {
                if (result.isConfirmed) {
                    this.$GenericService
                        .delete(`reservation/${id}`)
                        .then(res => {
                            this.getItems();
                            this.$GenericService.swalAlertSuccess();
                            this.$eventBus.$emit("refresh-rooms");
                        })
                        .catch(err => {
                            this.$store.dispatch("setSnackbar", {
                                text: ` ${err.response.data.message}`,
                                color: "error"
                            });
                        });
                }
            });
        },

        details(item) {
            this.$router.push({
                path: `/detailsReservation/${item.id}`,
                params: { id: item.id }
            });
        },

        sendMessage(item) {
            let text = `نظام ادارة الفنادق من مركز الكفيل لتقنية المعلومات,                                                            
            تفاصيل الحجز,                                                                                                                        
            رقم الحجز ${item.reservationNo}, نوع الحجز,  ${
                item.reservationType
            },                                                            
            الوقت ${item.checkinTime}, التاريخ ${item.checkinDate.substr(
                0,
                10
            )},                                                            
            وقت المغادرة ${item.checkoutTime}, تاريخ المغادرة,  ${
                item.checkoutDate
            },                                                            
            اسم الوفد  ${item.delegationName}, معرف الوفد ${
                item.delegationID
            },                                                            
            عدد اغراف المحجوزة ${item.reservedRoomsCount}, عدد النزلاء ${
                item.delegationCount
            },                                                            
            الشخص المعرف ${
                item.identifierIdentifierName
            },                                                                                          
            المستخدم  الذي قام بانشاء الحجز  ${item.createdUserUserName}, `;
            this.$GenericService
                .get("telegram/sendMessage?text=" + text)
                .then(res => {
                    this.$store.dispatch("setSnackbar", {
                        text: `تم ارسال تفاصيل الحجز الى قناة التليكرام بنجاح`
                    });
                })
                .catch(err => {
                    this.$store.dispatch("setSnackbar", {
                        text: ` ${err.data.message}`,
                        color: "error"
                    });
                })
                .finally(() => {
                    text = "";
                });
        },

        offReservation(id) {
            this.$GenericService
                .delete(`reservation/offReservation/${id}`)
                .then(res => {
                    this.getItems();
                    this.$store.dispatch("setSnackbar", {
                        text: `تم انهاء الحجز بنجاح`
                    });
                    this.$eventBus.$emit("refresh-rooms");
                })
                .catch(err => {});
        },

        openAddDialog() {
            var item = {
                dialogType: "add"
            };
            this.$store.dispatch("setBodyDetails", item);
            this.$store.dispatch("setDialog");
            this.$eventBus.$emit("refresh-rooms");
        },

        openEditDialog(item) {
            // let rooms = [
            // //     {
            // //         roomId: "keu5934875690342",
            // //         roomNumber: "1"
            // //     },
            // //     {
            // //         roomId: "keu5934875690342",
            // //         roomNumber: "2"
            // //     }
            // // ];
            // // this.$store.dispatch("setRooms", rooms);

            // item.dialogType = "edit";
            // this.$store.dispatch("setBodyDetails", item);
            // this.$store.dispatch("setDialog");
            // this.$eventBus.$emit("fill-fields");
            this.$router.push({
                path: `/reservation/edit/${item.id}`,
                params: { id: item.id }
            });
        },

        itemRowBackground: function(item) {
            return item.status ? "style-1" : "";
        }
    }
};
</script>
